.Mui-selected {
    color: grey !important;
  }
  
  .MuiTab-root {
    color: rgb(0, 0, 0) !important;
  }

  @font-face {
    font-family: 'Kanit Light';
    src: local('Kanit Light'), url('./Kanit-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

body {
    font-family: 'Prompt Medium', sans-serif;
}

.customTabColor {
  color: red !important; 
  /* the !important flag may be required in some cases to override default styles */
}