@font-face {
    font-family: 'Kanit';
    src: url('./Kanit-Thin.ttf') format('truetype');
    font-weight: 100; 
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url('./Kanit-ExtraLight.ttf') format('truetype');
    font-weight: 200; 
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url('./Kanit-Light.ttf') format('truetype');
    font-weight: 300; 
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url('./Kanit-Regular.ttf') format('truetype');
    font-weight: 400; 
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url('./Kanit-Medium.ttf') format('truetype');
    font-weight: 500; 
    font-style: normal;
}

body {
    font-family: 'Kanit', sans-serif !important;
}
